import { useUserStore } from '~/stores/user'
import navigateToHome from '~/helpers/navigateToHome'
import middlewareCheckContext from '~/helpers/middlewareCheckContext'

export default defineNuxtRouteMiddleware((to) => {
    if (useError().value || !middlewareCheckContext(to)) {
        return
    }

    const userStore = useUserStore()

    if (
        !userStore.isAdministrator
        && APP_ROLE_DEPENDENT_ROOT_ROUTE_NAMES.some((routeName: string) => (to.name as string).startsWith(routeName))
    ) {
        if (process.server) {
            throw showError({
                statusCode: 403,
                statusMessage: 'forbidden'
            })
        } else {
            return navigateToHome(+to.params.pid)
        }
    }
})
